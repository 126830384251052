import React, { useState, useEffect } from "react";
// import { Tooltip } from "../../layout/Tooltip";
import { Link } from "react-scroll";
import SocialIcons from "../../layout/SocialIcons";
import { motion } from "framer-motion";

const itemVariants = {
  open: {
    opacity: 1,
    y: 0,
    transition: { type: "spring", stiffness: 300, damping: 24 }
  },
  closed: { opacity: 0, y: 20, transition: { duration: 0.2 } }
};

const Header = ({ classicHeader, darkTheme, homeRef, handleNavClick }) => {
  const [isNavModalClose, setIsNavModalClose] = useState(window.innerWidth <= 768);
  const [isOpen, setIsOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      const isMobileSize = window.innerWidth <= 768;
      setIsMobile(isMobileSize);
      setIsNavModalClose(isMobileSize);
    };
    window.addEventListener('resize', handleResize);
    handleResize(); // Call it immediately to set initial state
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  
  return (
    <header id="header" className="sticky-top">
      {/* Navbar */}
      <nav className="primary-menu navbar navbar-expand-lg navbar-dark bg-dark border-bottom-0">
        <div className="container-fluid position-relative h-100 flex-lg-column ps-3 px-lg-3 pt-lg-3 pb-lg-2">
          {/* Logo */}
          <Link
            smooth
            duration={500}
            style={{ cursor: "pointer" }}
            to="home"
            className="mb-lg-auto mt-lg-4"
            onClick={(e) => {
              e.preventDefault();
              setIsNavModalClose(true);
            }}
          >
            <span className="bg-dark-2 rounded-pill p-2 mb-lg-1 d-none d-lg-inline-block">
              <img
                className="img-fluid rounded-pill d-block"
                src="images/profile.jpg"
                title="I'm Nick"
                alt="profile"
              />
            </span>
            <h1 className="text-5 text-white text-center mb-0 d-lg-block">
              Nicholas Dean
            </h1>
          </Link>
          {/* Logo End */}
          <div
            id="header-nav"
            className={
              isNavModalClose
                ? "collapse navbar-collapse w-100 my-lg-auto "
                : "show navbar-collapse w-100 my-lg-auto"
            }
          >
            <motion.ul
              className="navbar-nav text-lg-center my-lg-auto py-lg-3"
              initial={isMobile ? "closed" : "open"}
              animate={isMobile ? (isOpen ? "open" : "closed") : "open"}
              variants={{
                open: {
                  transition: {
                    type: "spring",
                    bounce: 0,
                    duration: 0.7,
                    delayChildren: 0.3,
                    staggerChildren: 0.05
                  }
                },
                closed: {
                  transition: {
                    type: "spring",
                    bounce: 0,
                    duration: 0.3
                  }
                }
              }}
              style={{ pointerEvents: isMobile ? (isOpen ? "auto" : "none") : "auto" }}
            >
              <motion.li className="nav-item" variants={itemVariants}>
                <Link
                  target={homeRef}
                  className="nav-link "
                  smooth
                  duration={500}
                  style={{ cursor: "pointer" }}
                  activeClass="active"
                  spy
                  to="home"
                  onClick={(e) => {
                    e.preventDefault();
                    setIsOpen(!isOpen);
                    setIsNavModalClose(true);
                  }}
                >
                  Home
                </Link>
              </motion.li>
              <motion.li className="nav-item" variants={itemVariants}>
                <Link
                  className="nav-link "
                  smooth
                  duration={500}
                  style={{ cursor: "pointer" }}
                  activeClass="active"
                  spy
                  to="about"
                  onClick={(e) => {
                    e.preventDefault();
                    setIsOpen(!isOpen);
                    setIsNavModalClose(true);
                  }}
                >
                  About Me
                </Link>
              </motion.li>
              <motion.li className="nav-item" variants={itemVariants}>
                <Link
                  className="nav-link "
                  smooth
                  duration={500}
                  style={{ cursor: "pointer" }}
                  activeClass="active"
                  spy
                  to="services"
                  onClick={(e) => {
                    e.preventDefault();
                    setIsOpen(!isOpen);
                    setIsNavModalClose(true);
                  }}
                >
                  What I Do
                </Link>
              </motion.li>
              <motion.li className="nav-item" variants={itemVariants}>
                <Link
                  className="nav-link "
                  smooth
                  duration={500}
                  style={{ cursor: "pointer" }}
                  activeClass="active"
                  spy
                  to="resume"
                  onClick={(e) => {
                    e.preventDefault();
                    setIsOpen(!isOpen);
                    setIsNavModalClose(true);
                  }}
                >
                  Resume
                </Link>
              </motion.li>
              <motion.li className="nav-item" variants={itemVariants}>
                <Link
                  className="nav-link "
                  smooth
                  duration={500}
                  style={{ cursor: "pointer" }}
                  activeClass="active"
                  spy
                  to="portfolio"
                  onClick={(e) => {
                    e.preventDefault();
                    setIsOpen(!isOpen);
                    setIsNavModalClose(true);
                  }}
                >
                  Portfolio
                </Link>
              </motion.li>
              {/* <motion.li className="nav-item" variants={itemVariants}>
                <Link
                  className="nav-link "
                  smooth
                  duration={500}
                  style={{ cursor: "pointer" }}
                  activeClass="active"
                  spy
                  to="blogs"
                  onClick={(e) => {
                    e.preventDefault();
                    setIsNavModalClose(true);
                  }}
                >
                  Blogs
                </Link>
              </motion.li> */}
              <motion.li className="nav-item" variants={itemVariants}>
                <Link
                  className="nav-link "
                  smooth
                  duration={500}
                  style={{ cursor: "pointer" }}
                  activeClass="active"
                  spy
                  to="contact"
                  onClick={(e) => {
                    e.preventDefault();
                    setIsOpen(!isOpen);
                    setIsNavModalClose(true);
                  }}
                >
                  Contact
                </Link>
              </motion.li>
              <motion.li className="nav-link d-sm-flex d-md-none" variants={itemVariants}>
                <div className="nav-link ">
                  <SocialIcons />
                </div>
              </motion.li>
            </motion.ul>
          </div>
          <SocialIcons className="d-none d-md-flex" />
          <div className="d-sm-flex d-md-none ms-auto">
            <motion.a
              href="#contact"
              className="btn btn-sm bg-primary text-white rounded-pill shadow-none smooth-scroll pt-1 pb-1"
              onClick={(e) => {
                e.preventDefault();
                setIsOpen(!isOpen);
                handleNavClick("contact");
              }}
              whileHover={{ scale: 1.050 }}
              whileTap={{ scale: 1.050 }}
            >
              Hire Me!
            </motion.a>
          </div>
          <button
            onClick={(e) => {
              setIsNavModalClose(!isNavModalClose);
              setIsOpen(!isOpen);
            }}
            className={
              isNavModalClose ? "navbar-toggler" : "navbar-toggler show"
            }
            id="navbar-toggler"
            type="button"
          >
            <span />
            <span />
            <span />
          </button>
        </div>
      </nav>
      {/* Navbar End */}
    </header>
  );
};

export default Header;
