import optimizeCert from "../../documents/UC-ce800a1b-8141-46fe-b99b-27e4ba097b92.pdf";
import meanCert from "../../documents/UC-c94ab286-37f2-46db-8b37-6ecdf5bb0bce.pdf";

export const educationDetails = [
  {
    yearRange: "2008 - 2010",
    title: "Media Arts & Animation",
    place: "Art Institute of Pittsburgh",
    desc: "I have learned a diverse set of skills related to the creative arts. These skills include 3D modeling, clay sculpting, color theory, and graphic design. I am proficient in using various software applications, such as Maya, Cinema4D, 3D Studio Max, ZBrush, Photoshop, and Illustrator, to create high-quality designs and models. These skills have allowed me to develop a strong foundation in the creative arts, as well as the ability to adapt to different mediums and technologies.",
    truncate: true
  },
  {
    yearRange: "2012 - 2014",
    title: "Web Design & Development",
    place: "Mira Costa & Palomar College",
    desc: "During my college education, I acquired expertise in several essential tools and technologies used in web development and design. These include HTML, CSS, and JavaScript, which are the building blocks of every website, as well as Adobe Photoshop and Illustrator, industry-standard graphic design software used for creating high-quality images and visuals.",
    truncate: true
  },
  {
    yearRange: "2019 - 2021",
    title: "Computer Science: Software Engineering",
    place: "Colorado Technical University",
    desc: "I learned a range of valuable technical skills during my time at CTU. These include proficiency in several programming languages such as HTML, CSS, JavaScript, Java, C++, and Python. I also gained knowledge of SQL for database management, as well as algorithms and data structures for efficient problem-solving.",
    truncate: true
  },
  {
    yearRange: "2022",
    title: "Ultimate Google Optimize Course",
    place: "UDEMY",
    download: optimizeCert,
    desc: "I am proficient in all types of tests available in Google Optimize and have gained extensive knowledge on creating the right test strategy for websites, depending on the objectives. Through my completion of the Google Optimize course, I have developed the ability to make data-driven decisions for websites. With my expertise in this area, I am equipped to effectively optimize websites and improve their overall performance.",
    truncate: true
  },
  {
    yearRange: "2023",
    title: "Angular & NodeJS - MEAN Stack",
    place: "UDEMY",
    download: meanCert,
    desc: "I completed a comprehensive course on Udemy where I learned how to set up a NodeJS + Express + MongoDB + Angular Application. The course taught me how to efficiently use NodeJS and Express, build reusable components in Angular, connect the backend with the Angular app through HttpClient service, add advanced features like file upload and pagination, implement users and authentication for security, handle errors gracefully, and much more.",
    truncate: false
  },
];

export const experienceDetails = [
  {
    yearRange: "2010 - Present",
    title: "Designer & Full-Stack Developer",
    place: "Freelance",
    desc: "As a freelance professional, I offer a range of services tailored to meet the unique needs of businesses across diverse industries. My expertise lies in crafting visually appealing graphics that effectively communicate brand messaging across a range of marketing channels, including webpage content, social media, email, and text marketing campaigns. Additionally, I provide comprehensive front-end and back-end development solutions that cater to the specific requirements of businesses such as restaurants, production facilities, and eCommerce stores.",
    truncate: false
  },
  {
    yearRange: "2013 - 2018",
    title: "Full-Stack Developer",
    place: "KESS In House",
    desc: "As a former full-stack developer, my primary responsibility was to develop and maintain the front-end of Shopify websites. In addition, I gained extensive experience in production software development, with a particular focus on image manipulation, large format printing automations, as well as inventory and warehouse management. Throughout my tenure, I was committed to ensuring that all software solutions were designed and implemented to the highest standards, with a keen attention to detail and a focus on achieving optimal functionality and user experience.",
    truncate: true
  },
  {
    yearRange: "2018 - Present",
    title: "Full-Stack Developer",
    place: "Backdrop Outlet",
    desc: "As a developer for a front-end Shopify website, my primary responsibilities included developing and maintaining various features to improve the customer experience. I was also responsible for production software development, including image manipulation and large format printing automations, as well as inventory and warehouse management. Through my work, I was able to ensure that our customers received a top-notch online shopping experience, while also optimizing our internal processes to improve efficiency and accuracy.",
    truncate: true
  },
  {
    yearRange: "2020 - Present",
    title: "Chief Technology Officer",
    place: "Soft Serve Clothing",
    desc: "As a skilled professional, I have been responsible for developing and maintaining a front-end Shopify website, which includes leveraging modern web development technologies to build and enhance the user experience. Additionally, I have extensive experience in production software development, with a specific focus on creating robust inventory and warehouse management solutions. My proficiency in these areas has allowed me to successfully design, implement, and maintain various software systems, ensuring smooth business operations and optimal performance.",
    truncate: false
  },
];

export const skills = [
  {
    name: "UI / UX",
    percent: 75,
  },
  {
    name: "HTML / CSS / Liquid",
    percent: 100,
  },
  {
    name: "JavaScript",
    percent: 80,
  },
  {
    name: "React JS",
    percent: 70,
  },
  {
    name: "Angular Js",
    percent: 60,
  },
  {
    name: "Python",
    percent: 75,
  },
  {
    name: "Rails",
    percent: 50,
  },
  {
    name: "PHP",
    percent: 75,
  },
  {
    name: "MySQL",
    percent: 75,
  },
  {
    name: "Bootstrap",
    percent: 95,
  },
];