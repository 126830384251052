import React from 'react';

const CookiePolicyModal = (props) => {

  return (
    <div
      className="modal fade bg-dark-1"
      id="cookiePolicyModal"
      tabIndex={-1}
      aria-labelledby="CookiePolicyModal"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-xl">
        <div
          className={
            "modal-content project--modal" + (props.darkTheme ? "bg-dark-2 text-light" : "")
          }
        >
          <div className="bar rounded-top">
            <button
              type="button"
              className={"btn-close me-1 mt-1 project--close" + (props.darkTheme ? "btn-close-white" : "")}
              data-bs-dismiss="modal"
              aria-label="Close"
            />
            <h2>Cookie Policy</h2>
            <div
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <span>
                <i
                  className="
                    fa-sharp
                    fa-solid
                    fa-circle-xmark"
                  >
                </i>
              </span>
            </div>
          </div>
          <div className="modal-body">
            <div
              className={
                "container ajax-container " +
                (props.darkTheme ? "bg-dark-2 text-light" : "")
              }
            >
              <h2
                className={
                  "text-6 font-weight-600 text-center mb-4" +
                  (props.darkTheme ? " text-white" : "")
                }
              >
                Cookie Policy
              </h2>
              <div className="row g-4">
                <div className="col-12">
                  <div className="featured-box style-3 mb-5">
                    <div
                      className={
                        "featured-box-icon text-white  shadow-sm rounded " +
                        (props.darkTheme ? "bg-dark-1" : "bg-primary")
                      }
                    >
                      <i className="fa-solid fa-1" />
                    </div>
                    <h3 className={props.darkTheme ? "text-white" : ""}>
                      Introduction
                    </h3>
                    <p
                      className={"mb-0 " + (props.darkTheme ? "text-white-50" : "")}
                    >
                      1.1 Our website uses cookies.<br /><br />
                      
                      1.2 Insofar as those cookies are not strictly necessary for the provision of our website and services,
                      we will ask you to consent to our use of cookies when you first visit our website and/or we will make this our cookies policy readily and clearly available within our website.
                    </p>
                  </div>
                </div>
                <div className="col-12">
                  <div className="featured-box style-3 mb-5">
                    <div
                      className={
                        "featured-box-icon text-white  shadow-sm rounded " +
                        (props.darkTheme ? "bg-dark-1" : "bg-primary")
                      }
                    >
                      <i className="fa-solid fa-2" />
                    </div>
                    <h3 className={props.darkTheme ? "text-white" : ""}>
                      Credit
                    </h3>
                    <p
                      className={"mb-0 " + (props.darkTheme ? "text-white-50" : "")}
                    >
                      2.1 This document was created using a template from SEQ Legal {"("}<a href="https://seqlegal.com" target="_blank" rel="noreferrer">https://seqlegal.com</a>{")"}.
                    </p>
                  </div>
                </div>
                <div className="col-12">
                  <div className="featured-box style-3 mb-5">
                    <div
                      className={
                        "featured-box-icon text-white  shadow-sm rounded " +
                        (props.darkTheme ? "bg-dark-1" : "bg-primary")
                      }
                    >
                      <i className="fa-solid fa-3" />
                    </div>
                    <h3 className={props.darkTheme ? "text-white" : ""}>
                      About cookies
                    </h3>
                    <p
                      className={"mb-0 " + (props.darkTheme ? "text-white-50" : "")}
                    >
                      3.1 A cookie is a file containing an identifier {"("}a string of letters and numbers{")"} that is sent by a web server to a web browser and is stored by the browser.
                      The identifier is then sent back to the server each time the browser requests a page from the server.<br /><br />
                    
                      3.2 Cookies may be either “persistent” cookie, “session” cookies or in certain cases “third party” cookies: a persistent cookie will be stored by a web browser
                      and will remain valid until its set expiry date, unless deleted by the user before the expiry date; a session cookie, on the other hand, will expire at the end
                      of the user session, when the web browser is closed.<br /><br />

                      3.3 Cookies do not typically contain any information that personally identifies a user, but personal information that we store about you may be linked to the
                      information stored in and obtained from cookies.
                    </p>
                  </div>
                </div>
                <div className="col-12">
                  <div className="featured-box style-3 mb-5">
                    <div
                      className={
                        "featured-box-icon text-white  shadow-sm rounded " +
                        (props.darkTheme ? "bg-dark-1" : "bg-primary")
                      }
                    >
                      <i className="fa-solid fa-4" />
                    </div>
                    <h3 className={props.darkTheme ? "text-white" : ""}>
                      Cookies that we use
                    </h3>
                    <p
                      className={"mb-0 " + (props.darkTheme ? "text-white-50" : "")}
                    >
                      4.1 We use cookies for the following purposes:<br /><br />
                      {"("}a{")"} authentication {""} we use cookies to identify you when you visit our website and as you navigate our website {"("}cookies used for this purpose are: web, browser, http or any other relevant type of Internet cookie, which may include, but is not limited to session cookies, persistent cookies, and/or third party cookies{")"};<br />
                      {"("}b{")"} status &ndash; we use cookies to help us to determine if you are logged into our website {"("}cookies used for this purpose are: web, browser, http or any other relevant type of Internet cookie, which may include, but is not limited to session cookies, persistent cookies, and/or third party cookies{")"};<br />
                      {"("}c{")"} shopping cart {"("}if applicable{")"} &ndash; we may also use cookies to maintain the state of your shopping cart as you navigate our website {"("}cookies used for this purpose are: web, browser, http or any other relevant type of Internet cookie, which may include, but is not limited to session cookies, persistent cookies, and/or third party cookies{")"};<br />
                      {"("}d{")"} personalisation &ndash; we use cookies to store information about your preferences and to personalise our website for you {"("}cookies used for this purpose are: web, browser, http or any other relevant type of Internet cookie, which may include, but is not limited to session cookies, persistent cookies, and/or third party cookies{")"};<br />
                      {"("}e{")"} security &ndash; we use cookies as an element of the security measures used to protect user accounts, including preventing fraudulent use of login credentials, and to protect our website and services generally {"("}cookies used for this purpose are: web, browser, http or any other relevant type of Internet cookie, which may include, but is not limited to session cookies, persistent cookies, and/or third party cookies{")"};<br />
                      {"("}f{")"} advertising {"("}if applicable{")"} &ndash; we use cookies to help us to display advertisements that will be relevant to you {"("}cookies used for this purpose are: web, browser, http or any other relevant type of Internet cookie, which may include, but is not limited to session cookies, persistent cookies, and/or third party cookies{")"};<br />
                      {"("}g{")"} analysis &ndash; we use cookies to help us to analyse the use and performance of our website and services {"("}cookies used for this purpose are: web, browser, http or any other relevant type of Internet cookie, which may include, but is not limited to session cookies, persistent cookies, and/or third party cookies{")"}; and<br />
                      {"("}h{")"} cookie consent {"("}if applicable{")"} &ndash; we use cookies to store your preferences in relation to the use of cookies more generally {"("}cookies used for this purpose are: web, browser, http or any other relevant type of Internet cookie, which may include, but is not limited to session cookies, persistent cookies, and/or third party cookies{")"}.
                    </p>
                  </div>
                </div>
                <div className="col-12">
                  <div className="featured-box style-3 mb-5">
                    <div
                      className={
                        "featured-box-icon text-white  shadow-sm rounded " +
                        (props.darkTheme ? "bg-dark-1" : "bg-primary")
                      }
                    >
                      <i className="fa-solid fa-5" />
                    </div>
                    <h3 className={props.darkTheme ? "text-white" : ""}>
                      Cookies used by our service providers
                    </h3>
                    <p
                      className={"mb-0 " + (props.darkTheme ? "text-white-50" : "")}
                    >
                      5.1 Our service providers use cookies and those cookies may be stored on your computer when you visit our website.<br /><br />

                      5.2 We use Google Analytics to analyse the use of our website. Google Analytics gathers information about website use by means of cookies. The information gathered relating to our website is used to create reports about the use of our website. Google{"'"}s privacy policy is available at: <a href="https://www.google.com/policies/privacy/" target="_blank" rel="noreferrer" >https://www.google.com/policies/privacy/</a>. The relevant cookies are: web, browser, http or any other relevant type of Internet cookie, which may include, but is not limited to session cookies, persistent cookies, and/or third party cookies.<br /><br />

                      5.3 *{"("}Not currently applicable but may become so in future{")"}. We publish Google AdSense interest-based advertisements on our website. These are tailored by Google to reflect your interests. To determine your interests, Google will track your behaviour on our website and on other websites across the web using cookies. OR We publish Google AdSense advertisements on our website. To determine your interests, Google will track your behaviour on our website and on other websites across the web using cookies. This behaviour tracking allows Google to tailor the advertisements that you see on other websites to reflect your interests {"("}but we do not publish interest-based advertisements on our website{")"}. You can view, delete or add interest categories associated with your browser by visiting: <a href="https://adssettings.google.com" target="_blank" rel="noreferrer">https://adssettings.google.com</a>. You can also opt out of the AdSense partner network cookie using those settings or using the Network Advertising Initiative{"'"}s multi-cookie opt-out mechanism at: <a href="http://optout.networkadvertising.org" target="_blank" rel="noreferrer">http://optout.networkadvertising.org</a>. However, these opt-out mechanisms themselves use cookies, and if you clear the cookies from your browser your opt-out will not be maintained. To ensure that an opt-out is maintained in respect of a particular browser, you may wish to consider using the Google browser plug-ins available at: <a href="https://support.google.com/ads/answer/7395996" target="_blank" rel="noreferrer">https://support.google.com/ads/answer/7395996</a>. The relevant cookies are: identify cookies.<br /><br />
                    </p>
                  </div>
                </div>
                <div className="col-12">
                  <div className="featured-box style-3 mb-5">
                    <div
                      className={
                        "featured-box-icon text-white  shadow-sm rounded " +
                        (props.darkTheme ? "bg-dark-1" : "bg-primary")
                      }
                    >
                      <i className="fa-solid fa-6" />
                    </div>
                    <h3 className={props.darkTheme ? "text-white" : ""}>
                      Managing cookies
                    </h3>
                    <p
                      className={"mb-0 " + (props.darkTheme ? "text-white-50" : "")}
                    >
                      6.1 Most browsers allow you to refuse to accept cookies and to delete cookies. The methods for doing so vary from browser to browser, and from version to version. You can however obtain up-to-date information about blocking and deleting cookies via these links:<br /><br />
                      {"("}a{")"} <a href="https://support.google.com/chrome/answer/95647?hl=en" target="_blank" rel="noreferrer">https://support.google.com/chrome/answer/95647?hl=en</a> {"("}Chrome{")"};<br />
                      {"("}b{")"} <a href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences" target="_blank" rel="noreferrer">https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences</a> {"("}Firefox{")"};<br />
                      {"("}c{")"} <a href="http://www.opera.com/help/tutorials/security/cookies/" target="_blank" rel="noreferrer">http://www.opera.com/help/tutorials/security/cookies/</a> {"("}Opera{")"};<br />
                      {"("}d{")"} <a href="https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies" target="_blank" rel="noreferrer">https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies</a> {"("}Internet Explorer{")"};<br />
                      {"("}e{")"} <a href="https://support.apple.com/kb/PH21411" target="_blank" rel="noreferrer">https://support.apple.com/kb/PH21411</a> {"("}Safari{")"}; and<br />
                      {"("}f{")"} <a href="https://privacy.microsoft.com/en-us/windows-10-microsoft-edge-and-privacy" target="_blank" rel="noreferrer">https://privacy.microsoft.com/en-us/windows-10-microsoft-edge-and-privacy</a> {"("}Edge{")"}.<br /><br />

                      6.2 Blocking all cookies will have a negative impact upon the usability of many websites.<br /><br />

                      6.3 If you block cookies, you may not be able to use all the features on our website.

                    </p>
                  </div>
                </div>
                <div className="col-12">
                  <div className="featured-box style-3 mb-5">
                    <div
                      className={
                        "featured-box-icon text-white  shadow-sm rounded " +
                        (props.darkTheme ? "bg-dark-1" : "bg-primary")
                      }
                    >
                      <i className="fa-solid fa-7" />
                    </div>
                    <h3 className={props.darkTheme ? "text-white" : ""}>
                      Cookie preferences
                    </h3>
                    <p
                      className={"mb-0 " + (props.darkTheme ? "text-white-50" : "")}
                    >
                      7.1 Where applicable you can manage your preferences relating to the use of cookies on our website if and when prompted.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

}

export default CookiePolicyModal;