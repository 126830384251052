import React from "react";

const Icon = (props) => {
  const icons = {
    "github": "fa-brands fa-github",
    "html": "fa-brands fa-html5",
    "css": "fa-brands fa-css3-alt",
    "javascript": "fa-brands fa-square-js",
    "js": "fa-brands fa-square-js",
    "php": "fa-brands fa-php",
    "mysql": "fa-solid fa-database",
    "nosql": "fa-duotone fa-database",
    "mongodb": "fa-solid fa-server",
    "python": "fa-brands fa-python",
    "ruby": "fa-solid fa-code",
    "rails": "fa-solid fa-code",
    "shopify": "fa-brands fa-shopify",
    "react": "fa-brands fa-react",
    "angular": "fa-brands fa-angular",
    "slack": "fa-brands fa-slack",
    "figma": "fa-brands fa-figma",
    "node": "fa-brands fa-node",
    "aws": "fa-brands fa-aws",
    "liquid": "fa-solid fa-droplet",
    "webhook": "fa-solid fa-webhook",
    "hookdeck": "fa-solid fa-webhook",
    "dreamhost": "fa-solid fa-server",
    "illustrator": "fa-solid fa-pen-nib",
    "wordpress": "fa-brands fa-wordpress",
    "font-awesome": "fa-solid fa-font-awesome",
    "pen & paper": "fa-solid fa-pen-to-square",
    "pen and paper": "fa-solid fa-pen-to-square",
    "bootstrap": "fa-brands fa-bootstrap",
    "sketch": "fa-brands fa-sketch",
    "photoshop": "fa-solid fa-image",
    "cinema 4d": "fa-solid fa-cube",
    "firebase": "fa-solid fa-code-branch",
    "download": "fa-solid fa-download",
    "certified": "fa-solid fa-badge-check",
    "photography": "fa-solid fa-camera",
    "framer": "fa-solid fa-frame",
  }
  return (
    <i className={icons[props.icon]} />
  )
}

export default Icon;