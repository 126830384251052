import React, { useEffect } from 'react';
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

const ProjectCard = ({ darkTheme, isLoading, project, imagesLoaded, setImagesLoaded, setSelectedProjectDetails, index, ProjectDataArray }) => {
  // Animation
  const control = useAnimation();
  const [ref, inView] = useInView();

  const floatUpAnimation = {
    visible: { y: 0, opacity: 1 },
    hidden: { y: 30, opacity: 0 }
  }

  useEffect(() => {
    if (inView && !isLoading) {
      control.start("visible")
    }
  }, [control, inView, isLoading]);

  return (
    <motion.div
      className={
        "col-sm-6 col-lg-4 filter-item " +
        project.categories.join(" ")
      }
      ref={ref}
      variants={floatUpAnimation}
      initial="hidden"
      animate={control}
      transition={{ 
        duration: 0.5,
      }}
    >
      <div
        className="portfolio-box rounded"
      >
        {project.subtitle && (
          <div className="corner-ribbon top-right bg-light sticky shadow">{project.subtitle}</div>
        )}
        <div className="bar rounded-top position-relative">
          <h2>
            {project.title}
          </h2>
          <i></i>
        </div>
        <div className="portfolio-img rounded-bottom">
          <img
            className="img-fluid d-block portfolio-image"
            src={project.thumbImage}
            onLoad={() => {
              setImagesLoaded(imagesLoaded + 1);
            }}
            alt=""
          />
          <div className="portfolio-overlay">
            <span
              className="popup-ajax stretched-link"
              href=""
              onClick={() => {
                setSelectedProjectDetails(ProjectDataArray[index]);
              }}
              data-bs-toggle="modal"
              data-bs-target="#projectModal"
            />
            <div className="portfolio-overlay-details">
              <h5 className="text-white fw-400">
                {project.title}
              </h5>
              {project.subtitle && (
                <h6 className="text-white text-1 fw-400">
                  <span className="badge bg-secondary fw-400 font-monospace">{project.subtitle}</span>
                </h6>
              )}
              {project.categories.map((category, indx) => (
                <span className={"badge bg-danger fw-300 " + (project.categories.length > 1 ? "me-1 " : "")} key={indx}>
                  {category}
                </span>
              ))}
              {/* <span className="text-light"></span> */}
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  )
}

export default ProjectCard;