import React from "react";
import ServiceCard from "./ServiceCard";

const Services = ({ classicHeader, darkTheme, isLoading }) => {

  // services details
  const services = [
    {
      name: "Frontend Development",
      desc: "I take great satisfaction in building websites from the ground up. Whether it's creating user-friendly interfaces, implementing elegant design, or ensuring that websites are reliable and efficient, every step of the process is a source of joy for me.",
      icon: "fa-solid fa-code-simple",
      tags: ["HTML","CSS","JavaScript","React","Angular","Liquid","Shopify","Wordpress"],
    },
    {
      name: "Backend Development",
      desc: "I have a strong passion for data analysis and manipulation. There's something deeply satisfying about digging into data, extracting insights, and using that information to create elegant, effective solutions.",
      icon: "fa-solid fa-terminal",
      tags: ["PHP","MySQL","Python","Rails","MongoDB","Firebase","AWS","Github","Hookdeck"],
    },
    {
      name: "UI/UX Design",
      desc: "I appreciate content that is organized in a straightforward manner, design that is uncluttered and efficient, and interactions that are carefully considered and purposeful.",
      icon: "fa-solid fa-layer-plus",
      tags: ["Figma","Framer","Font-Awesome","Bootstrap","Sketch","Pen & Paper"],
    },
    {
      name: "Graphic Design",
      desc: "I take pride in my ability to use color and other design elements to create visual messages that resonate with target audiences, helping businesses achieve their marketing goals.",
      icon: "fa-sharp fa-regular fa-object-group",
      tags: ["Photoshop","Illustrator","Cinema 4D","Pen & Paper","Photography"],
    },
  ];

  return (
    <section
      id="services"
      className={"section " + (darkTheme ? "bg-dark-2" : "bg-light")}
    >
      <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
        {/* Heading */}
        <div className="position-relative d-flex text-center mb-5">
          <h2
            className={
              "text-24  text-uppercase fw-600 w-100 mb-0 " +
              (darkTheme ? "text-white-50  opacity-1" : "text-light  opacity-4")
            }
          >
            Services
          </h2>
          <p
            className={
              "text-9  fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
              (darkTheme ? "text-white" : "text-dark")
            }
          >
            What I Do
            <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
          </p>
        </div>
        {/* Heading end*/}
        {/* content start */}
        <div className="row">
          <div className="col-lg-11 mx-auto">
            <div className="row">
              {services.length > 0 &&
                services.map((service, index) => (
                  <ServiceCard
                    key={index}
                    darkTheme={darkTheme}
                    isLoading={isLoading}
                    service={service}
                  />
                ))}
            </div>
          </div>
        </div>
        {/* content end */}
      </div>
    </section>
  );
};

export default Services;
