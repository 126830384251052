import React, { useState, Fragment } from "react";
import { commonConfig } from "../../config/commonConfig";
import { Analytics } from '@vercel/analytics/react';

import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import ClassicHeader from "../Header/ClassicHeader";
import PreLoader from "../../layout/Preloader";
import CookiePopup from "../../layout/Cookies/CookiePopup";

const Layout = ({children, isLoading}) => {

  const classicHeader = commonConfig.classicHeader;
  const darkTheme = commonConfig.darkTheme;

  const handleNavClick = (section) => {
    document.getElementById(section).scrollIntoView({ behavior: "smooth" });
  };

  const [scrollTopVisible, setScrollTopVisible] = useState(false);

  const checkScrollTop = () => {
    let scrollTopBtn = document.getElementById("back-to-top");

    if (scrollTopBtn) {
      if (
        document.body.scrollTop > 400 ||
        document.documentElement.scrollTop > 400
      ) {
        setScrollTopVisible(true);
      } else {
        setScrollTopVisible(false);
      }
    }
  };

  if (typeof window !== "undefined") {
    window.addEventListener("scroll", checkScrollTop);
  }

  return (
    <Fragment>
      <div
        style={{ position: "relative" }}
        className={classicHeader ? "" : "side-header"}
      >
        {isLoading && <PreLoader></PreLoader>}

        <div id="main-wrapper">
          {classicHeader ? (
            <ClassicHeader handleNavClick={handleNavClick}></ClassicHeader>
          ) : (
            <Header handleNavClick={handleNavClick}></Header>
          )}

          <div id="content" role="main">

            {children}

            <div className="position-relative">
              <CookiePopup
                classicHeader={classicHeader}
                darkTheme={darkTheme}
                isLoading={isLoading}
              />
            </div>
          </div>
          <Footer
            classicHeader={classicHeader}
            darkTheme={darkTheme}
            handleNavClick={handleNavClick}
          ></Footer>
        </div>
        {/* back to top */}
        <span
          id="back-to-top"
          className="rounded-circle"
          style={{ display: scrollTopVisible ? "inline" : "none" }}
          onClick={() => {
            window.scrollTo({ top: 0, behavior: "smooth" });
          }}
        >
          <i className="fa fa-chevron-up"></i>
        </span>
        {/* back to top end */}

      </div>
      <Analytics />
    </Fragment>
  );

}

export default Layout;