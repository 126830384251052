import React from "react";
import { motion } from "framer-motion";

import {educationDetails, experienceDetails, skills} from "./data";
import resumeFile from "../../documents/resume.pdf";
import ResumeCard from "./ResumeCard";
import SkillProgressBar from "./SkillProgressBar";

const Resume = ({ classicHeader, darkTheme, isLoading }) => {

  return (
    <section
      id="resume"
      className={"section " + (darkTheme ? "bg-dark-1" : "")}
    >
      <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
        {/* Heading */}
        <div className="position-relative d-flex text-center mb-5">
          <h2
            className={
              "text-24  text-uppercase fw-600 w-100 mb-0 " +
              (darkTheme ? "text-muted opacity-1" : "text-light opacity-4")
            }
          >
            Summary
          </h2>
          <p
            className={
              "text-9 text-dark fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
              (darkTheme ? "text-white" : "text-dark")
            }
          >
            {" "}
            Resume
            <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
          </p>
        </div>
        {/* Heading end*/}
        <div className="row gx-5">
          {/* My Education */}
          <div className="col-md-6">
            <h2
              className={
                "text-6 fw-600 mb-4 " + (darkTheme ? "text-white" : "")
              }
            >
              My Education
            </h2>
            {educationDetails.length > 0 &&
              educationDetails.map((value, index) => (
                <ResumeCard key={index} darkTheme={darkTheme} index={index} details={value} isLoading={isLoading} />
              ))}
          </div>
          {/* My Experience */}
          <div className="col-md-6">
            <h2
              className={
                "text-6 fw-600 mb-4 " + (darkTheme ? "text-white" : "")
              }
            >
              My Experience
            </h2>
            {experienceDetails.length > 0 &&
              experienceDetails.map((value, index) => (
                <ResumeCard key={index} darkTheme={darkTheme} index={index} details={value} isLoading={isLoading} />
              ))}
          </div>
        </div>
        {/* My Skills */}
        <h2
          className={
            "text-6 fw-600 mt-4 mb-4 " + (darkTheme ? "text-white" : "")
          }
        >
          My Skills
        </h2>
        <div className="row gx-5">
          {skills.length > 0 &&
            skills.map((skill, index) => (
              <SkillProgressBar key={index} skill={skill} isLoading={isLoading} darkTheme={darkTheme} />
            ))}
        </div>
        <div className="text-center mt-5">
          <motion.a
            className="btn btn-outline-secondary rounded-pill shadow-none"
            href={resumeFile}
            download
            whileHover={{ scale: 1.050 }}
            whileTap={{ scale: 1.050 }}
          >
            Download CV
            <span className="ms-1">
              <i className="fas fa-download" />
            </span>
          </motion.a>
        </div>
      </div>
    </section>
  );
};

export default Resume;
