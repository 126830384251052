import React, { useEffect } from 'react';
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

const SkillProgressBar = ({ skill, isLoading, darkTheme }) => {
  
  // Animation
  const control = useAnimation();
  const [ref, inView] = useInView();

  const progressAnimation = {
    visible: { width: skill.percent + "%" },
    hidden: { width: "0%" }
  }

  useEffect(() => {
    if (inView && !isLoading) {
      control.start("visible")
    }
  }, [control, inView, isLoading]);

  return (
    <div className="col-md-6">
      <p
        className={
          " fw-500 text-start mb-2 " +
          (darkTheme ? "text-light" : "text-dark")
        }
      >
        {skill.name}{" "}
        <span className="float-end">{skill.percent}%</span>
      </p>
      <div
        className={
          "progress progress-sm mb-4 " + (darkTheme ? "bg-dark" : "")
        }
      >
        <motion.div
          className="progress-bar"
          role="progressbar"
          // style={{ width: skill.percent + "%" }}
          aria-valuenow={skill.percent}
          aria-valuemin={0}
          aria-valuemax={100}
          ref={ref}
          variants={progressAnimation}
          initial="hidden"
          animate={control}
          transition={{
            type: "linear",
            stiffness: 260,
            damping: 20
          }}
        />
      </div>
    </div>
  );

}

export default SkillProgressBar;