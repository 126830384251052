import React, { useEffect, useRef, Fragment, useState } from "react";
import Slider from "react-slick";
import { DiscussionEmbed } from 'disqus-react';
import { motion, useAnimation } from "framer-motion";
import { useMediaQuery } from 'react-responsive';

const ProjectDetailsModal = ({ darkTheme, projectDetails }) => {
  // Slider
  const sliderRef = useRef();
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const [imageExpanded, setImageExpanded] = useState(false);
  const control = useAnimation();
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
  
  const imageAnimate = {
    visible: { opacity: 1 },
    hidden: { opacity: 0 }
  }

  const closeExpandedImage = () => {
    const image = document.querySelector('.expanded-image');
    if (image !== null) {
      image.style.opacity = 0;
      setSelectedImageIndex(null);
      setImageExpanded(false);
      // image.addEventListener('transitionend', () => {
      //   setSelectedImageIndex(null);
      //   setImageExpanded(false);
      // }, { once: true });
    } else {
      setSelectedImageIndex(null);
    }
  };

  var settings = {
    dots: true,
    arrows: true,
    infinite: true,
    adaptiveHeight: true,
    speed: 500,
    slidesToShow: projectDetails?.slidesToShow,
    slidesToScroll: projectDetails?.slidesToScroll,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ]
  };

  useEffect(() => {
    sliderRef.current.slickGoTo(0);
  }, [projectDetails]);

  useEffect(() => {
    // Set the height of the slider container to fit the expanded image
    if (selectedImageIndex !== null) {
      const image = document.querySelector('.expanded-image');
      if (image !== null) {
        control.start("visible");
        document.querySelector(".expanded-container").style.height = `${image.clientHeight}px`;
      }
    } else {
      document.querySelector(".slider-container").style.height = 'auto';
    }

  }, [selectedImageIndex, control]);


  // Slider /End


  // Image Zoom

  return (
    <Fragment>
      <div
        className="modal fade bg-dark-1"
        id="projectModal"
        tabIndex={-1}
        aria-labelledby="ProjectModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl">
          <div
            className={
              "modal-content project--modal" + (darkTheme ? "bg-dark-2 text-light" : "")
            }
          >
            <div className="bar rounded-top">
              <button
                type="button"
                className={"btn-close me-1 mt-1 project--close" + (darkTheme ? "btn-close-white" : "")}
                data-bs-dismiss="modal"
                aria-label="Close"
              />
              <h2>{projectDetails?.title}</h2>
              {/* <h2>{projectDetails?.url?.name}</h2> */}
              <div
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span>
                  <i
                    className="
                      fa-sharp
                      fa-solid
                      fa-circle-xmark"
                    >
                  </i>
                </span>
              </div>
            </div>
            <div className="modal-body">
              <div
                className={
                  "container ajax-container " +
                  (darkTheme ? "bg-dark-2 text-light" : "")
                }
              >
                <h2
                  className={
                    "text-6 font-weight-600 text-center mb-4" +
                    (darkTheme ? " text-white" : "")
                  }
                >
                  {projectDetails?.title}
                </h2>
                <div className="row">
                  <motion.div
                    className="expanded-image-contents"
                    variants={imageAnimate}
                    initial="hidden"
                    animate={control}
                    transition={{
                      type: "spring",
                      stiffness: 260,
                      damping: 20
                    }}
                  >
                    {selectedImageIndex !== null && (
                      <div className="col-md-12 slider-container expanded-container">
                          <div className="expanded-image-container">
                            <span className="close rounded-circle" onClick={closeExpandedImage}>
                              <i className="fa-solid fa-magnifying-glass-minus"></i>
                            </span>
                            <img src={projectDetails?.sliderImages[selectedImageIndex]} alt="" className="expanded-image" />
                          </div>
                      </div>
                    )} 
                  </motion.div>
                  <span className={`row g-1 ${imageExpanded ? "hide-details" : ""}`}>
                    <div className="col-md-7">
                      <div className="slider-container">
                        <Slider {...settings} ref={sliderRef} className="my-slider">
                          {projectDetails?.sliderImages?.length > 0 &&
                            projectDetails?.sliderImages?.map((image, index) => (
                              <div className="item" key={index}>
                                <img
                                  alt=""
                                  src={image}
                                  onClick={!isMobile ? () => {
                                    setSelectedImageIndex(index);
                                    setImageExpanded(true);
                                  } : (e) => e.preventDefault}
                                  className={`img-fluid slider--image ${isMobile ? '' : 'slider--image-hover'}`}
                                />
                                {!isMobile && 
                                  <Fragment>
                                    <div className="content-overlay"
                                      onClick={() => {
                                        setSelectedImageIndex(index);
                                        setImageExpanded(true);
                                      }}
                                    />
                                    <span
                                      className="maximize-project-image"
                                      onClick={() => {
                                        setSelectedImageIndex(index);
                                        setImageExpanded(true);
                                      }}
                                    >
                                      <i className="fa-solid fa-magnifying-glass-plus"></i>
                                    </span>
                                  </Fragment>
                                }
                              </div>
                            ))}
                        </Slider>
                      </div>
                    </div>
                    <div className="col-md-5">
                      {projectDetails?.projectInfo && (
                        <h4
                          className={
                            "text-4 font-weight-600" +
                            (darkTheme ? " text-white" : "")
                          }
                        >
                          Project Info:
                        </h4>
                      )}
                      {projectDetails?.projectInfo?.split("\r\n").map((line, indx) => {
                        const linkRegex = /\[link:(.*?)\](.*?)\[\/link\]/g;
                        const matches = [...line.matchAll(linkRegex)];
                        
                        if (matches.length > 0) {
                          let currentIndex = 0;
                          const elements = matches.map((match, index) => {
                            const linkUrl = match[1];
                            const linkText = match[2];
                            const beforeLink = line.substring(currentIndex, match.index);
                            currentIndex = match.index + match[0].length;
                            
                            return (
                              <React.Fragment key={index}>
                                {beforeLink}
                                <a href={linkUrl} target="_blank" rel="noopener noreferrer">{linkText}</a>
                              </React.Fragment>
                            );
                          });
                          
                          const remainingText = line.substring(currentIndex);
                          elements.push(<React.Fragment key={matches.length}>{remainingText}</React.Fragment>);
                          
                          return <p key={indx}>{elements}</p>;
                        }
                        
                        return <p key={indx}>{line}</p>;
                      })}
                      {/* {projectDetails?.projectInfo?.split("\r\n").map((line, indx) =>
                        line && <p key={indx}>{line}</p>
                      )} */}
                      <h4
                        className={
                          "text-4 font-weight-600" +
                          (darkTheme ? " text-white" : "") +
                          (projectDetails?.projectInfo ? " mt-4" : "")
                          
                        }
                      >
                        Project Details:
                      </h4>
                      <ul
                        className={
                          "list-style-2 " + (darkTheme ? "list-style-light" : "")
                        }
                      >
                        <li>
                          <span
                            className={
                              "text-dark font-weight-600 me-2" +
                              (darkTheme ? " text-white" : "")
                            }
                          >
                            Client:
                          </span>
                          {projectDetails?.client}
                        </li>
                        <li>
                          <span
                            className={
                              "text-dark font-weight-600 me-2" +
                              (darkTheme ? " text-white" : "")
                            }
                          >
                            Platforms:
                          </span>
                          {projectDetails?.platforms.map((platform, indx) => (
                            <span className="badge bg-secondary fw-400 me-1" key={indx}>
                            {platform}
                          </span>
                          ))}
                        </li>
                        <li>
                          <span
                            className={
                              "text-dark font-weight-600 me-2" +
                              (darkTheme ? " text-white" : "")
                            }
                          >
                            Tech:
                          </span>
                          {projectDetails?.technologies.map((tech, indx) => (
                            <span className="badge bg-secondary fw-400 me-1" key={indx}>
                              {tech}
                            </span>
                          ))}
                        </li>
                        <li>
                          <span
                            className={
                              "text-dark font-weight-600 me-2" +
                              (darkTheme ? " text-white" : "")
                            }
                          >
                            Industry:
                          </span>
                          {projectDetails?.industry}
                        </li>
                        <li>
                          <span
                            className={
                              "text-dark font-weight-600 me-2" +
                              (darkTheme ? " text-white" : "")
                            }
                          >
                            Date:
                          </span>
                          {projectDetails?.date}
                        </li>
                        <li>
                          <span
                            className={
                              "text-dark font-weight-600 me-2" +
                              (darkTheme ? " text-white" : "")
                            }
                          >
                            URL:
                          </span>
                          <a
                            href={projectDetails?.url?.link}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {projectDetails?.url?.name}
                          </a>
                        </li>
                      </ul>
                      <div className="row no-gutters align-items-center">
                        <div
                          className={
                            "col-auto text-dark font-weight-600" +
                            (darkTheme ? " text-white" : "")
                          }
                        >
                          Share:
                        </div>
                        <div className="col-auto">
                          <ul
                            className={
                              "social-icons" +
                              (darkTheme ? " social-icons-muted" : "")
                            }
                          >
                            <li className="social-icons-facebook">
                              <a
                                data-toggle="tooltip"
                                href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(projectDetails?.socialLinks?.facebook)}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                data-original-title="Facebook"
                              >
                                <i className="fab fa-facebook-f" />
                              </a>
                            </li>
                            <li className="social-icons-twitter">
                              <a
                                data-toggle="tooltip"
                                href={`https://twitter.com/intent/tweet?url=${encodeURIComponent(projectDetails?.socialLinks?.twitter)}&text=${encodeURIComponent('Check out this project!')}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                data-original-title="Twitter"
                              >
                                <i className="fab fa-twitter" />
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </span>
                </div>
              </div>
              <hr className="mb-5" />
              <DiscussionEmbed
                  shortname='dailey-project'
                  config={{
                    url: projectDetails?.url?.link,
                    identifier: projectDetails?.id,
                    title: projectDetails?.title,
                    language: 'zh_EN'
                  }}
              />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ProjectDetailsModal;
