import React, { useEffect } from 'react';
import CookieConsent, { Cookies } from 'react-cookie-consent';
import CookiePolicyModal from './CookiePolicyModal';
import { motion, useAnimation } from "framer-motion";

const CookiePopup = ({ classicHeader, isLoading, darkTheme}) => {

  // Animation
  const control = useAnimation();

  const consentAnimation = {
    visible: { opacity: 1, scale: 1 },
    hidden: { opacity: 0, scale: 0 }
  }

  useEffect(() => {
    if (!isLoading) {
      control.start("visible")
    }
  }, [control, isLoading]);

  return (
    <div
      id="cookie-consent-wrapper"
    >
      <CookieConsent
        location="bottom"
        cookieName="cookie-consent"
        style={{ background: "transparent", left: "auto", textAlign: "center", width: "auto", textWrap: "wrap", margin: "none" }}
        contentStyle={{ margin: "none", maxWidth: "260px", flex: "1 0 260px" }}
        containerClasses="rounded p-2"
        buttonClasses="d-none" // Hide the default consent button
        expires={7}
      >
        <motion.div
          className="cookie-consent-container rounded"
          variants={consentAnimation}
          initial="hidden"
          animate={control}
          transition={{
            duration: 0.8,
            delay: 0.5,
            ease: [0, 0.71, 0.2, 1.01]
          }}
        >
          <div className="bar rounded-top">
            <h2>Cookie Consent</h2>
            <i></i>
          </div>
          <div className="cookie-consent-content p-3 bg-light text-muted text-1 rounded-bottom">
            <span className="cookie-pop-bg">
              <i className="fa-solid fa-cookie"></i>
              <i className="fa-solid fa-cookie-bite"></i>
            </span>
            <p>Cookies are used on this website to give you the best experience possible.</p>
            <button
              className="btn btn-sm pt-1 pb-1 bg-primary text-white shadow-none rounded-pill me-2"
              onClick={() => {
                Cookies.set("cookie-consent", "true", { expires: 7 });
                document.getElementById("cookie-consent-wrapper").style.display = "none";
              }}
            >
              Okay
            </button>
            <button
              className="btn btn-sm btn-outline-secondary pt-1 pb-1 shadow-none rounded-pill"
              data-bs-toggle="modal"
              data-bs-target="#cookiePolicyModal"
              // onClick={() => {
              //   Cookies.set("cookie-consent", "true", { expires: 7 });
              //   document.getElementById("cookie-consent-wrapper").style.display = "none";
              // }}
            >
              <i className="fa-solid fa-eye me-1"></i>Policy
            </button>
          </div>
        </motion.div>
      </CookieConsent>
      <div className="project-details-modal">
        <CookiePolicyModal
          darkTheme={darkTheme}
        />
      </div>
    </div>
  )

}

export default CookiePopup;