import React, { Fragment } from "react";

import Home from "../Home/Home";
import AboutUs from "../About/About";
import Services from "../Services/Services";
import Resume from "../Resume/Resume";
import Portfolio from "../Portfolio/Portfolio";
// import Testimonials from "./Testimonials/Testimonials";
import Contact from "../Contact/Contact";

const Main = ({ classicHeader, darkTheme, handleNavClick, isLoading }) => {
  return (
    <>
      <Home
        classicHeader={classicHeader}
        darkTheme={darkTheme}
        handleNavClick={handleNavClick}
      ></Home>
      <AboutUs
        classicHeader={classicHeader}
        darkTheme={darkTheme}
        isLoading={isLoading}
      ></AboutUs>
      <Services
        classicHeader={classicHeader}
        darkTheme={darkTheme}
        isLoading={isLoading}
      ></Services>
      <Resume
        classicHeader={classicHeader}
        darkTheme={darkTheme}
        isLoading={isLoading}
      ></Resume>
      <Portfolio
        classicHeader={classicHeader}
        darkTheme={darkTheme}
        isLoading={isLoading}
      ></Portfolio>
      {/* <Testimonials
        classicHeader={classicHeader}
        darkTheme={darkTheme}
      ></Testimonials> */}
      <Contact
        classicHeader={classicHeader}
        darkTheme={darkTheme}
        isLoading={isLoading}
      ></Contact>
    </>
  );
};

export default Main;