import React, { Fragment, useState, useEffect } from 'react';
import { Tooltip } from '../../layout/Tooltip';
import Icon from "../../layout/Icons";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

const ResumeCard = (props) => {

  const [isTruncated, setIsTruncated] = useState(props.details.truncate);

  const handleToggle = () => {
    setIsTruncated(!isTruncated);
  };

  const truncateText = (text, maxChars) => {
    if (isTruncated) {
      return text.slice(0, maxChars) + '...';
    } else {
      return text;
    }
  };


  // Animation
  const control = useAnimation();
  const [ref, inView] = useInView();

  const resumeAnimation = {
    visible: { y: 0, opacity: 1 },
    hidden: { y: 30, opacity: 0 }
  }

  useEffect(() => {
    if (inView && !props.isLoading) {
      control.start("visible")
    }
  }, [control, inView, props.isLoading]);

  return (
    <Fragment>
      <motion.div
        className={"bg-white rounded p-4 mb-4 " + (props.darkTheme ? "bg-dark" : "bg-white border")}
        ref={ref}
        variants={resumeAnimation}
        initial="hidden"
        animate={control}
        transition={{
          duration: 0.5,
        }}
      >
        <div className="d-flex flex-direction-row mb-2">
          <p className="badge bg-primary text-2 fw-400 me-2 mb-0">
            {props.details.yearRange}
          </p>
          {props.details.download && (
              <Tooltip
                text="Certificate"
                placement="right">
                  <a
                    href={props.details.download}
                    download
                    className="text-warning"
                  >
                    <Icon icon="certified" />
                  </a>

              </Tooltip>
            )}
        </div>
        <h3 className={"text-5 " + (props.darkTheme ? "text-white" : "")}>{props.details.title}</h3>
        <p className={props.darkTheme ? "text-primary" : "text-danger"}>{props.details.place}</p>
        <p
          id={`description-${props.index}`}
          className={`mb-0 truncated-text ${props.darkTheme ? "text-white-50" : ""}`}
          style={{
            overflow: isTruncated ? "hidden" : "visible",
            textOverflow: isTruncated ? "ellipsis" : "initial",
            whiteSpace: isTruncated ? "nowrap" : "normal",
          }}
        >
          {truncateText(props.details.desc, 200)}
        </p>
        <button onClick={handleToggle} className="btn btn-link p-0 text-2">
          Read {isTruncated ? "more" : "less"} {isTruncated && (<i className='fas fa-caret-down text-2'></i>)} {!isTruncated && (<i className='fas fa-caret-up'></i>)}
        </button>
      </motion.div>
    </Fragment>
  );
}

export default ResumeCard;