import "./styles.css";
import "./App.scss";
import { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import { commonConfig } from "./config/commonConfig";

import Layout from "./components/Layout/Layout";
import Main from "./components/Main/Main";
import NotFound from "./components/404/404";

function App() {
  const classicHeader = commonConfig.classicHeader;
  const darkTheme = commonConfig.darkTheme;

  const handleNavClick = (section) => {
    document.getElementById(section).scrollIntoView({ behavior: "smooth" });
  };

  const [isLoading, setisLoading] = useState(true);
  useEffect(() => {
    const loadingTimeout = setTimeout(() => {
      setisLoading(false);
    }, 1000);
    return () => {
      clearTimeout(loadingTimeout);
    };
  }, []);

  return (
    <Layout isLoading={isLoading}>
      <Router>
        <Routes>
          <Route path="/" element={<Main darkTheme={darkTheme} classicHeader={classicHeader} handleNavClick={handleNavClick} isLoading={isLoading} />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    </Layout>
  );
}

export default App;
