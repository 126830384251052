export const Filters = {
  DEVELOPMENT: "Development",
  DESIGN: "Design",
  // PHOTOS: "Photography"
};

export const ProjectDataArray = [
  {
    id: "P1",
    title: "Chops",
    subtitle: "Landing Page",
    projectInfo: "This project encompassed both development and design aspects, with the aim of conceptualizing an e-commerce platform that specialized in professional chef jackets. Our primary goal was to create a one-page landing page, highlighting the product details, the mission statement, and the chef responsible for the jacket's design. It is important to note that the client had distinct design preferences and was firm in their stance of not making any modifications to the aesthetics of the platform to ensure that their vision was fully realized.",
    client: "Chops",
    technologies: [
      "HTML",
      "CSS",
      "Liquid",
      "Bootstrap",
      "JavaScript",
      "jQuery",
      "Photoshop",
      "Illustrator"
    ],
    platforms: [
      "Shopify",
      "Github",
      "Figma"
    ],
    industry: "eCommerce, Cookwear",
    date: "January, 2023",
    url: {
      name: "chopsjacket.com",
      link: "https://chops-jacket.myshopify.com",
    },
    socialLinks: {
      facebook: "https://daileyproject.com/",
      twitter: "https://daileyproject.com/",
    },
    thumbImage: "images/projects/chops/Chops-Thumb-min.jpg",
    sliderImages: [
      "images/projects/chops/Chops-Frontend-Desktop-1.jpg",
      "images/projects/chops/Chops-Frontend-Mobile-1.jpg",
    ],
    slidesToShow: 2,
    slidesToScroll: 2,
    categories: [Filters.DESIGN,Filters.DEVELOPMENT],
  },
  {
    id: "P2",
    title: "Soft Serve Clothing",
    subtitle: "Full Website",
    projectInfo: "During my tenure at Soft Serve Clothing, I held the position of Full-Stack Developer. In this capacity, I was responsible for developing a fully tailored Shopify theme using cutting-edge Javascript libraries and contemporary UI/UX practices for the front-end. Additionally, I designed and executed an entire warehouse and order management software utilizing a range of front-end languages and back-end technologies such as PHP, MySQL, and Python. This system was integrated with their inventory and order management, allowing for seamless connectivity with Shopify and their shipping partner Easy Post. Due to the client's rapid growth, I led the migration to Shopify Plus and crafted a custom checkout experience, incorporating Ruby on Rails to build discount and shipping scripts. The discounts included a variety of features, such as smart upsell items during checkout, buy X get Y, tiered discounts, free gifts, post-purchase upsells, and more.",
    client: "Soft Serve Clothing",
    technologies: [
      "HTML",
      "CSS",
      "Liquid",
      "JavaScript",
      "Rails",
      "Python",
      "PHP",
      "MySQL",
      "Photoshop",
      "Illustrator"
    ],
    platforms: [
      "Shopify Plus",
      "Github",
      "Hookdeck",
      "AWS"
    ],
    industry: "eCommerce, Fashion",
    date: "June 01, 2020",
    url: {
      name: "SoftServeClothing.com",
      link: "https://softserveclothing.com",
    },
    socialLinks: {
      facebook: "https://daileyproject.com/",
      twitter: "https://daileyproject.com/",
    },
    thumbImage: "images/projects/softserveclothing/frontend/SS-Thumb.jpg",
    sliderImages: [
      "images/projects/softserveclothing/frontend/SS-Home.jpg",
      "images/projects/softserveclothing/frontend/SS-Landing-1.jpg",
      "images/projects/softserveclothing/frontend/SS-Product-1.jpg",
      "images/projects/softserveclothing/frontend/SS-Product-2.jpg",
    ],
    slidesToShow: 2,
    slidesToScroll: 2,
    categories: [Filters.DESIGN,Filters.DEVELOPMENT],
  },
  {
    id: "P3",
    title: "Shipment Tracking",
    subtitle: "New Features",
    projectInfo: "This project aimed to develop an advanced tracking page to enhance the user experience for customers accessing their shipment information. Customers are directed to this page either through shipment confirmation emails or via order shipment lookup operations. The client's requirements included designing a system similar to certain Shopify applications and external services but without the associated high costs.\r\nOur solution was built using jQuery and AJAX technologies. These tools facilitated the creation of a seamless communication channel to the client's servers, effectively transmitting customer requests. Upon receipt, the servers interface with the shipping provider's API to retrieve the specific tracking information for the order or shipment in question.\r\nWe designed the system to accommodate both national and international shipping through USPS and FedEx. This approach ensures the solution covers a broad spectrum of logistics, providing end-users with a reliable, accessible and cost-efficient tracking platform.",
    client: "Soft Serve Clothing",
    technologies: [
      "HTML",
      "CSS",
      "JavaScript",
      "jQuery",
      "PHP"
    ],
    platforms: [
      "Shopify +",
      "Github"
    ],
    industry: "eCommerce, Fashion",
    date: "Dec 01, 2022",
    url: {
      name: "SoftServeClothing.com",
      link: "https://softserveclothing.com",
    },
    socialLinks: {
      facebook: "https://daileyproject.com/",
      twitter: "https://daileyproject.com/",
    },
    thumbImage: "images/projects/softserveclothing/frontend/SS-Thumb-2.jpg",
    sliderImages: [
      "images/projects/softserveclothing/frontend/SS-Tracking-Transit-1.jpg",
      "images/projects/softserveclothing/frontend/SS-Tracking-Delivered-1.jpg",
    ],
    slidesToShow: 2,
    slidesToScroll: 2,
    categories: [Filters.DESIGN, Filters.DEVELOPMENT],
  },
  {
    id: "P4",
    title: "Sandpiper of California",
    subtitle: "Full Website",
    projectInfo:
      "As a full-stack developer, I undertook the project of migrating Sandpiper from Magento to Shopify. The primary objective was to create a comprehensive Shopify store by employing standard and emerging front-end design techniques and utilizing back-end languages to extract data from Magento and store it in a database for record-keeping purposes. It is noteworthy that while I was responsible for the technical implementation of the project, the business may have made minor alterations to the website's aesthetics that were outside of my purview. Throughout the project, I ensured that all deliverables were completed in a professional and timely manner, meeting the business's requirements and achieving a successful outcome.",
    client: "Sandpiper of California",
    technologies: [
      "HTML",
      "CSS",
      "Liquid",
      "JavaScript",
      "Python",
      "Ruby",
    ],
    platforms: [
      "Shopify",
      "Magento",
      "Github"
    ],
    industry: "eCommerce, Luggage",
    date: "February 19, 2019",
    url: {
      name: "SandpiperCA.com",
      link: "https://sandpiperca.com/",
    },
    socialLinks: {
      facebook: "https://www.facebook.com/sandpiperca",
      instagram: "https://www.instagram.com/sandpiperofca/",
    },
    thumbImage: "images/projects/sandpiper/SandPiper-Thumb-1-min.jpg",
    sliderImages: [
      "images/projects/sandpiper/SandPiper-Frontend-Home-Desktop-1-min.jpg",
      "images/projects/sandpiper/SandPiper-Frontend-Colection-Desktop-1-min.jpg",
      "images/projects/sandpiper/SandPiper-Frontend-Product-Desktop-1-min.jpg",
      "images/projects/sandpiper/SandPiper-Frontend-ProductInfo-Desktop-1-min.jpg",
      "images/projects/sandpiper/SandPiper-Frontend-Reviews-Desktop-1-min.jpg",
      "images/projects/sandpiper/SandPiper-Frontend-About-Desktop-1-min.jpg",
    ],
    slidesToShow: 2,
    slidesToScroll: 2,
    categories: [Filters.DESIGN, Filters.DEVELOPMENT],
  },
  {
    id: "P5",
    title: "Evaga Lounge",
    subtitle: "Full Website",
    projectInfo:
      "In partnership with [link:https://www.itsjustl.com/]Its Just L[/link], I engaged in a collaborative effort to develop the brand identity for a local restaurant situated in Oceanside, CA. Throughout this comprehensive branding endeavor, our team closely collaborated with the restaurant owners to conceive and materialize a cohesive visual identity. Our scope of work encompassed the creation of logos, marketing materials, professional photo shoots, and the design and development of a fully functional website, culminating in the delivery of a comprehensive branding package. [link:https://www.itsjustl.com/]Its Just L[/link] adeptly assumed the roles of photographer and graphic designer, while I skillfully undertook the responsibility of web development.",
    client: "Evaga Lounge",
    technologies: [
      "HTML",
      "CSS",
      "JavaScript",
      "PHP"
    ],
    platforms: [
      "Wordpress",
      "Github"
    ],
    industry: "Food & Beverages",
    date: "May 1, 2023",
    url: {
      name: "EvagaLounge.com",
      link: "https://evagalounge.com/",
    },
    socialLinks: {
      facebook: "https://www.facebook.com/profile.php?id=100092306005584",
      instagram: "https://www.instagram.com/evagaloungeoside/",
    },
    thumbImage: "images/projects/evagalounge/evaga-desktop-thumb.jpg",
    sliderImages: [
      "images/projects/evagalounge/evaga-desktop.jpg",
      "images/projects/evagalounge/evaga-contact-desktop.jpg",
      "images/projects/evagalounge/evaga-mobile.jpg",
    ],
    slidesToShow: 1,
    slidesToScroll: 1,
    categories: [Filters.DESIGN, Filters.DEVELOPMENT],
  },
  {
    id: "P6",
    title: "Ideate Design",
    subtitle: "Landing Page",
    projectInfo: "My involvement in this project was limited to the development phase. At the time, the objective was to create a succinct landing page that highlighted the key capabilities of the business, with a focus on delivering a streamlined user experience. The website was designed to enable seamless communication between the business and its clients by incorporating several interactive features. However, it's worth noting that since my involvement, things may have changed, and the website may have undergone further updates or modifications. Nevertheless, during my time working on the project, I worked closely with the team to ensure that the website met all the necessary technical requirements and was fully functional.",
    client: "Ideate Design",
    technologies: [
      "HTML",
      "CSS",
      "JavaScript",
      "PHP",
      "Photoshop"
    ],
    platforms: [
      "Wordpress",
      "Github"
    ],
    industry: "Construction, Consultation",
    date: "July, 2014",
    url: {
      name: "IdeateDesignBuild.com",
      link: "https://ideatedesignbuild.com/",
    },
    socialLinks: {
      facebook: "https://daileyproject.com/",
      twitter: "https://daileyproject.com/",
    },
    thumbImage: "images/projects/ideatedesignbuild/project-1.jpg",
    sliderImages: [
      "images/projects/ideatedesignbuild/project-1.jpg",
    ],
    slidesToShow: 1,
    slidesToScroll: 1,
    categories: [Filters.DEVELOPMENT],
  },
  {
    id: "P7",
    title: "Sharp Chef",
    subtitle: "Full Website",
    projectInfo: "In a recent pivotal project, I spearheaded the redevelopment of a front-end e-commerce website while simultaneously engineering a comprehensive back-end management system. My role involved intricately rebuilding a purchased theme to enhance user experience, introducing an innovative grid-like product listing by color and style, and integrating an in-cart upsell feature to boost sales opportunities. Additionally, I designed a custom company story page to deepen brand engagement and developed a user-friendly FAQ section with accordion-style drop-downs for ease of navigation. On the backend, my focus shifted to creating a robust system for managing orders, inventory, and shipments, which involved seamless integration with Shopify's and Pitney Bowes' APIs. This dual approach resulted in a visually appealing, user-centric online storefront and a streamlined, efficient back-end system, significantly improving both the customer experience and the business's operational efficiency.",
    client: "Joey Maggiore",
    technologies: [
      "HTML",
      "CSS",
      "JavaScript",
      "Tailwind CSS",
      "PHP",
      "MySQL",
      "Python"
    ],
    platforms: [
      "Shopify",
      "Pitney Bowes",
      "Shipstation"
    ],
    industry: "Culinary Apparel & Accessories",
    date: "September, 2023",
    url: {
      name: "Sharp Chef",
      link: "https://sharpchef.com",
    },
    socialLinks: {
      facebook: "https://www.facebook.com/SharpChefCo/",
      instagram: "https://www.instagram.com/sharpchefco/",
    },
    thumbImage: "images/projects/sharpchef/Sharp-Chef-HomePage-Thumb.jpg",
    sliderImages: [
      "images/projects/sharpchef/Sharp-Chef-HomePage-Desktop.png",
      "images/projects/sharpchef/Sharp-Chef-HomePage-Mobile.png",
      "images/projects/sharpchef/Sharp-Chef-Collection-Desktop.png",
      "images/projects/sharpchef/Sharp-Chef-Collection-Mobile.png",
      "images/projects/sharpchef/Sharp-Chef-Product-Desktop.png",
      "images/projects/sharpchef/Sharp-Chef-Product-Mobile.png",
      "images/projects/sharpchef/Sharp-Chef-Story-Desktop.png",
      "images/projects/sharpchef/Sharp-Chef-Story-Mobile.png",
      "images/projects/sharpchef/Sharp-Chef-Cart-Product-Desktop.png",
      "images/projects/sharpchef/Sharp-Chef-Cart-Product-Open-Desktop.png",
      "images/projects/sharpchef/SharpChef-Cart-Recording.gif",
    ],
    slidesToShow: 2,
    slidesToScroll: 2,
    categories: [Filters.DEVELOPMENT, Filters.DESIGN],
  },

  // {
  //   id: "P7",
  //   title: "Project Title 7",
  //   projectInfo:
  //     "Quidam lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure. Lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure.",
  //   client: "Ruby Clinton",
  //   technologies: [
  //     "iOS",
  //     "HTML5",
  //     "CSS3",
  //     "PHP",
  //     "Java"
  //   ],
  //   platforms: [
  //     "Shopify",
  //     "Github",
  //     "Hookdeck",
  //     "AWS"
  //   ],
  //   industry: "Art & Design",
  //   date: "July 16, 2019",
  //   url: {
  //     name: "www.example.com",
  //     link: "https://www.example.com",
  //   },
  //   socialLinks: {
  //     facebook: "http://www.facebook.com/",
  //     twitter: "http://www.twitter.com/",
  //     google: "http://www.google.com/",
  //     instagram: "http://www.instagram.com/",
  //     mail: "mailto:example@gmail.com",
  //   },
  //   thumbImage: "images/projects/project-7.jpg",
  //   sliderImages: [
  //     "images/projects/project-1.jpg",
  //     "images/projects/project-5.jpg",
  //   ],
  //   slidesToShow: 1,
  //   slidesToScroll: 1,
  //   categories: [Filters.DESIGN, Filters.PHOTOS],
  // },
  // {
  //   id: "P8",
  //   title: "Project Title 8",
  //   projectInfo:
  //     "Quidam lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure. Lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure.",
  //   client: "Ruby Clinton",
  //   technologies: [
  //     "iOS",
  //     "HTML5",
  //     "CSS3",
  //     "PHP",
  //     "Java"
  //   ],
  //   platforms: [
  //     "Shopify",
  //     "Github",
  //     "Hookdeck",
  //     "AWS"
  //   ],
  //   industry: "Art & Design",
  //   date: "July 16, 2019",
  //   url: {
  //     name: "www.example.com",
  //     link: "https://www.example.com",
  //   },
  //   socialLinks: {
  //     facebook: "http://www.facebook.com/",
  //     twitter: "http://www.twitter.com/",
  //     google: "http://www.google.com/",
  //     instagram: "http://www.instagram.com/",
  //     mail: "mailto:example@gmail.com",
  //   },
  //   thumbImage: "images/projects/project-7.jpg",
  //   sliderImages: [
  //     "images/projects/project-1.jpg",
  //     "images/projects/project-5.jpg",
  //   ],
  //   slidesToShow: 1,
  //   slidesToScroll: 1,
  //   categories: [Filters.DESIGN, Filters.PHOTOS],
  // },
]