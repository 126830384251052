import React, { useEffect } from "react";
import resumeFile from "../../documents/resume.pdf";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

const AboutUs = ({ classicHeader, darkTheme, isLoading }) => {
  const getAge = () => Math.floor((new Date() - new Date('03/03/1989').getTime()) / 3.15576e+10);
  const age = getAge();

  // Animation
  const control = useAnimation();
  const [ref, inView] = useInView();

  const iconVariant = {
    visible: { rotate: 360, scale: 1 },
    hidden: { scale: 0 }
  }

  const iconHeaderVariant = {
    visible: { opacity: 1, scale: 1 },
    hidden: { opacity: 0, scale: 0 }
  }

  useEffect(() => {
    if (inView && !isLoading) {
      control.start("visible")
    }
  }, [control, inView, isLoading]);

  return (
    <section id="about" className={"section " + (darkTheme ? "bg-dark-1" : "")}>
      <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
        {/* Heading */}
        <div className="position-relative d-flex text-center mb-5">
          <h2
            className={
              "text-24  text-uppercase fw-600 w-100 mb-0 " +
              (darkTheme ? "text-muted opacity-1" : "text-light opacity-4")
            }
          >
            About Me
          </h2>
          <p
            className={
              "text-9 text-dark fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
              (darkTheme ? "text-white" : "text-dark")
            }
          >
            Get To Know Me
            <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
          </p>
        </div>
        {/* Heading end*/}
        <div className="row gy-5">
          {/* About me content start */}
          <div className="col-lg-7 col-xl-8 text-center text-lg-start">
            <h1
              className={
                "text-7 fw-600 mb-3 " + (darkTheme ? "text-white" : "")
              }
            >
              I'm <span className="text-primary">Nick</span>, a Full-Stack
              Developer
            </h1>
            <p className={darkTheme ? "text-white-50" : ""}>
                As a web designer &amp; developer, I'm passionate about creating simple,
                clean, and user-friendly websites that provide real value to their
                visitors. Over the years, I've had the pleasure of working with many
                clients, helping them achieve exceptional results through my expertise
                and attention to detail. From concept to launch, I pride myself on
                building beautiful and functional websites one line of code at a time.
                Let's work together to create a website that meets your unique needs and exceeds your expectations.
            </p>
          </div>
          {/* About me content end */}
          {/* about me personal detials start */}
          <div className="col-lg-5 col-xl-4 text-center text-lg-start">
            <div className="ps-lg-4">
              <ul
                className={
                  "list-style-2 " +
                  (darkTheme ? "list-style-light text-light" : "")
                }
              >
                <li>
                  <span className="fw-600 me-2">Name:</span>Nicholas Dean
                </li>
                <li>
                  <span className="fw-600 me-2">Email:</span>
                  <a href="mailto:nick@daileyproject.com">nick@daileyproject.com</a>
                </li>
                <li>
                  <span className="fw-600 me-2">Age:</span>{age}
                </li>
                <li className="border-0">
                  <span className="fw-600 me-2">From:</span>Oceanside,
                  California
                </li>
              </ul>
              <motion.a
                href={resumeFile}
                download
                className="btn btn-primary rounded-pill"
                whileHover={{ scale: 1.050 }}
                whileTap={{ scale: 1.050 }}
              >
                Download CV
              </motion.a>
            </div>
          </div>
          {/* about me personal details end */}
        </div>
        {/* projects rewards counting start */}
        <div
          className={
            "brands-grid separator-border mt-5 " +
            (darkTheme ? "separator-border-light" : "")
          }
        >
          <div className="row">
            <div className="col-6 col-md-3">
              <div className="featured-box style-4 text-center">
                <motion.div
                  className={
                    "featured-box-icon text-primary shadow-sm rounded " +
                    (darkTheme ? "bg-dark-1" : "bg-light")
                  }
                  ref={ref}
                  variants={iconVariant}
                  initial="hidden"
                  animate={control}
                  transition={{
                    type: "spring",
                    stiffness: 260,
                    damping: 20
                  }}
                >
                  <i className="fa-solid fa-file-certificate"></i>
                </motion.div>
                <motion.div
                  ref={ref}
                  variants={iconHeaderVariant}
                  initial="hidden"
                  animate={control}
                  transition={{
                    type: "spring",
                    stiffness: 260,
                    damping: 20
                  }}
                >
                  <h4
                    className={
                      "text-12  mb-0 " +
                      (darkTheme ? "text-white-50" : "text-muted")
                    }
                  >
                    <span>10</span>+
                  </h4>
                  <p className={"mb-0 " + (darkTheme ? "text-light" : "")}>
                    Years Experiance
                  </p>
                </motion.div>
              </div>
            </div>
            <div className="col-6 col-md-3">
              <div className="featured-box style-4 text-center">
                <motion.div
                  className={
                    "featured-box-icon text-primary shadow-sm rounded " +
                    (darkTheme ? "bg-dark-1" : "bg-light")
                  }
                  ref={ref}
                  variants={iconVariant}
                  initial="hidden"
                  animate={control}
                  transition={{
                    type: "spring",
                    stiffness: 260,
                    damping: 20
                  }}
                >
                  <i className="fa-solid fa-face-smile-hearts"></i>
                </motion.div>
                <motion.div
                  ref={ref}
                  variants={iconHeaderVariant}
                  initial="hidden"
                  animate={control}
                  transition={{
                    type: "spring",
                    stiffness: 260,
                    damping: 20
                  }}
                >
                  <h4
                    className={
                      "text-12  mb-0 " +
                      (darkTheme ? "text-white-50" : "text-muted")
                    }
                  >
                    <span>30</span>+
                  </h4>
                  <p className={"mb-0 " + (darkTheme ? "text-light" : "")}>
                    Happy Clients
                  </p>
                </motion.div>
              </div>
            </div>
            <div className="col-6 col-md-3">
              <div className="featured-box style-4 text-center">
                <motion.div
                    className={
                      "featured-box-icon text-primary shadow-sm rounded " +
                      (darkTheme ? "bg-dark-1" : "bg-light")
                    }
                    ref={ref}
                    variants={iconVariant}
                    initial="hidden"
                    animate={control}
                    transition={{
                      type: "spring",
                      stiffness: 260,
                      damping: 20
                    }}
                  >
                  <i className="fa-solid fa-list-check"></i>
                </motion.div>
                <motion.div
                  ref={ref}
                  variants={iconHeaderVariant}
                  initial="hidden"
                  animate={control}
                  transition={{
                    type: "spring",
                    stiffness: 260,
                    damping: 20
                  }}
                >
                  <h4
                    className={
                      "text-12  mb-0 " +
                      (darkTheme ? "text-white-50" : "text-muted")
                    }
                  >
                    <span>200</span>+
                  </h4>
                  <p className={"mb-0 " + (darkTheme ? "text-light" : "")}>
                    Projects Finished
                  </p>
                </motion.div>
              </div>
            </div>
            <div className="col-6 col-md-3">
              <div className="featured-box style-4 text-center">
                <motion.div
                    className={
                      "featured-box-icon text-primary shadow-sm rounded " +
                      (darkTheme ? "bg-dark-1" : "bg-light")
                    }
                    ref={ref}
                    variants={iconVariant}
                    initial="hidden"
                    animate={control}
                    transition={{
                      type: "spring",
                      stiffness: 260,
                      damping: 20
                    }}
                >
                  <i className="fa-regular fa-cup-togo"></i>
                </motion.div>
                <motion.div
                  ref={ref}
                  variants={iconHeaderVariant}
                  initial="hidden"
                  animate={control}
                  transition={{
                    type: "spring",
                    stiffness: 260,
                    damping: 20
                  }}
                >
                  <h4
                    className={
                      "text-12  mb-0 " +
                      (darkTheme ? "text-white-50" : "text-muted")
                    }
                  >
                    <span>7K</span>+
                  </h4>
                  <p className={"mb-0 " + (darkTheme ? "text-light" : "")}>
                    Cups of Coffee
                  </p>
                </motion.div>
              </div>
            </div>
          </div>
        </div>
        {/* projects rewards counting end */}
      </div>
    </section>
  );
};

export default AboutUs;
