import React, { useEffect } from 'react';
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { Tooltip } from "../../layout/Tooltip";
import Icon from "../../layout/Icons";


const ServiceCard = ({isLoading, service, darkTheme}) => {
  // Animation
  const control = useAnimation();
  const [ref, inView] = useInView();

  const floatUpAnimation = {
    visible: { y: 0, opacity: 1 },
    hidden: { y: 30, opacity: 0 }
  }

  useEffect(() => {
    if (inView && !isLoading) {
      control.start("visible")
    }
  }, [control, inView, isLoading]);

  return (
    <motion.div
      className="col-md-6"
      ref={ref}
      variants={floatUpAnimation}
      initial="hidden"
      animate={control}
      transition={{ 
        duration: 0.5,
      }}
    >
      <div className="featured-box style-3 mb-5">
        <div
          className={
            "featured-box-icon text-primary  shadow-sm rounded " +
            (darkTheme ? "bg-dark-1" : "bg-white")
          }
        >
          <i className={service.icon} />
        </div>
        <h3 className={darkTheme ? "text-white" : ""}>
          {service.name}
        </h3>
        {service.tags.length > 0 && (
          <ul className="social-icons justify-content-start icon--list">
            {service.tags.map((tag, indx) => (
              <li className="social-icons-facebook" key={indx}>
                <Tooltip text={tag} placement="bottom">
                  <div>
                    <Icon icon={tag.toLowerCase()} />
                  </div>
                </Tooltip>
              </li>
            ))}
          </ul>
        )}
        <hr />
        <p
          className={"mb-0 " + (darkTheme ? "text-white-50" : "")}
        >
          {service.desc}
        </p>
      </div>
    </motion.div>
  )

}

export default ServiceCard;